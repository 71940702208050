import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Sketch from '../../components/sketch';
import sketchSarsa from '../../scripts/sSarsa/sSarsa';
import sketchQ from '../../scripts/sQlearningSarsa/sQlearningSarsa';
import Layout from '../../components/layout';
import DateConverter from '../../components/dateConverter';
export const _frontmatter = {
  "title": "SARSA vs. Q-learning",
  "date": "2021-09-15",
  "slug": "sarsa",
  "author": "JAN MALTE LICHTENBERG"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Layout mdxType="Layout">
      <div>
        <DateConverter frontmatter={props.pageContext.frontmatter} mdxType="DateConverter" />
        <p className="blogtitle">
          {props.pageContext.frontmatter.title}
        </p>
      </div>
      <p>{`bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla`}</p>
      <Sketch sketch={sketchSarsa} mdxType="Sketch" />
      <p>{`bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla`}</p>
      <Sketch sketch={sketchQ} mdxType="Sketch" />
    </Layout>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      